@import 'theme';

body {
  background-color: $default-background-color;
}

.app {  
  background-color: $default-background-color;
  color: $default-text-color;
  line-height: $default-line-height;

  display: flex;
  flex-direction: column;

  /* text-align: center; */
}

/* CONVENIENCE CLASSES */

.center-image {
	margin-top: auto !important;
	margin-bottom: auto !important;
	margin-left: auto !important;
	margin-right: auto !important;
	display: block;
}
