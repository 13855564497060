@import 'theme';

.body {
    display: flex;
    width: 80%;
    max-width: $body-max-width;
    margin: 0 auto $footer-height auto;

    ul {
        list-style-type: disclosure-closed;
        margin-top: 0;

        ::marker {
            color: $theme-primary;
        }
    }
}