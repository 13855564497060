$theme-primary: #6600b9;
$theme-hover: #910AFF;
$theme-active: #4C008A;

$body-max-width: 1000px;
$footer-height: 100px;

$default-background-color: #FCFCFC;
$default-text-color: #444;
$default-line-height: 1.4;

$color-against-primary: $default-background-color;