@import 'theme';

.header {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 80%;
    max-width: $body-max-width;
    margin: 1em auto 1em auto;

    border: 2px solid $theme-primary;
    border-radius: 1em;
    padding: 0.5em;

    .icon-container {
        display: flex;

        .header-icon {
            display: initial;
    
            width: 3em;
    
            margin-left: 0.25em;
            margin-right: 0.25em;

            cursor: pointer;
            &.active {
                cursor: alias;
            }

            .active-underline {
                border: 1px solid $theme-primary;
                margin: 0 auto;
                width: 50%;
            }

            &:hover {
                .active-underline {
                    border: 1px solid $theme-hover;
                }
            }
        }

        .icon-fill {
            path {
                fill: $theme-primary;
            }
            &:hover path {
                fill: $theme-hover;
            }
        }
    
        .icon-stroke {
            path {
                stroke: $theme-primary;
            }
            &:hover path {
                stroke: $theme-hover;
            }
        }
    }
}

.header-container {
    background-color: $default-background-color;
    position: sticky;
    top: 0;
}